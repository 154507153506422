<template>
    <div id="app">
        <el-container>
            <el-header height="64px">
                <div style="display: flex">
                    <div style="height: 64px">
                        <img style="margin: 16px 0;height: 32px;cursor: pointer;" @click="$router.push({name: 'Index'})"
                             src="https://play-lh.googleusercontent.com/r3TfuTEkPgg0jv5tVse5SqseSN31-yOhKGdBshdxNbdlvt0_bTC2QAKaxfcnj4_8gg=w480-h960-rw">
                    </div>
                    <div style="padding: 0 10px;line-height: 64px;font-size: 20px;color: var(--el-text-color-regular);">
                        PixelColor
                        <el-divider direction="vertical" style="font-size: 32px"></el-divider>
                    </div>
                </div>
                <div>
                    <el-switch style="margin: 16px" v-model="dark" active-color="#444444" @change="changeDark"
                               active-icon="Moon" inactive-icon="Sunny" inline-prompt></el-switch>
                </div>
            </el-header>
            <el-container style="background-color: var(--el-border-color-extra-light)" :style="{height: height + 'px'}">
                <aside-nav ref="nav">
                    <aside-group title="素材库">
                        <aside-item icon="Files" title="全局图库" index="Index"></aside-item>
                        <aside-item icon="Star" title="素材评分" index="Score"></aside-item>
                        <aside-item icon="Clock" title="每日表现" index="Metric"></aside-item>
                        <aside-item icon="Setting" title="配置文件" index="Config"></aside-item>
                    </aside-group>
                    <aside-group title="Library">
                        <aside-item icon="Reading" title="分类管理" index="Category"></aside-item>
                        <aside-item icon="Aim" title="新手引导图" index="Rookie"></aside-item>
                        <aside-item icon="Star" title="新手引导表现" index="RookieStat"></aside-item>
                        <aside-item icon="Timer" title="每日上新" index="Update"></aside-item>
                        <aside-item icon="Orange" title="轮播图" index="Carousel"></aside-item>
                        <aside-item icon="Compass" title="臻选" index="Chosen"></aside-item>
                        <aside-item icon="Present" title="Bonus" index="Bonus"></aside-item>
                        <aside-item icon="View" title="预览" index="LibraryView"></aside-item>
                    </aside-group>
                    <aside-group title="Discovery">
                        <aside-item icon="Calendar" title="每日一图(Daily)" index="Daily"></aside-item>
                        <aside-item icon="Collection" title="集合(Collection)" index="Collection"></aside-item>
                        <aside-item icon="Ticket" title="拼图(Jigsaw)" index="Jigsaw"></aside-item>
                        <aside-item icon="Postcard" title="收集活动(CollectionEvent)" index="CollectionEvent"></aside-item>
                        <aside-item icon="View" title="预览" index="DiscoveryView"></aside-item>
                    </aside-group>
                    <aside-group title="Scape">
                        <aside-item icon="HomeFilled" title="场景管理" index="Scene"></aside-item>
                    </aside-group>
                    <aside-group title="Create">
                        <aside-item icon="Files" title="Gallery" index="CreateGallery"></aside-item>
                    </aside-group>
                    <aside-group title="运营活动">
                      <aside-item icon="Monitor" title="弹窗" index="Popup"></aside-item>
                    </aside-group>
                    <aside-group title="内购">
                      <aside-item index="Subscription" title="订阅活动" icon="Goods"></aside-item>
                      <aside-item index="NewbieSales" title="新用户活动" icon="Goods"></aside-item>
                    </aside-group>
                </aside-nav>
                <el-main>
                    <el-scrollbar>
                        <router-view></router-view>
                    </el-scrollbar>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import {useDark, useToggle} from '@vueuse/core';
import AsideNav from "./base/AsideNav.vue";
import AsideGroup from "./base/AsideGroup.vue";
import AsideItem from "./base/AsideItem.vue";
import axios from "ts-axios-new";

export default {
    name: 'App',
    components: {AsideGroup, AsideItem, AsideNav},
    data() {
        return {
            dark: window.matchMedia('(prefers-color-scheme: dark)').matches,
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight - 64,
            media: window.matchMedia('(prefers-color-scheme: dark)'),
            country_list: [], env: {CDN_URL: import.meta.env.VITE_CDN_URL}, authorization: '',
            user: {
                user_id: '',
                avatar: '',
                name: '',
                email: '',
                access: [],
            },
        }
    },
    methods: {
        changeDark() {
            useToggle(useDark())(this.dark);
        },
        handleScroll() {
            this.height = document.documentElement.clientHeight - 64;
            this.width = document.documentElement.clientWidth;
        },
    },
    mounted() {
        window.onresize = _ => {
            this.handleScroll();
        };
        this.media.addEventListener('change', e => {
            this.dark = e.matches;
            this.changeDark();
        });
        this.changeDark();
        axios.get('/cms/v1/country').then(res => {
            this.country_list = res.data.data.countryList;
        });
    }
}

</script>

<style>
html, body {
    margin: 0;
    padding: 0;
}

#highlight {
    position: absolute;
    border: 2px solid #0aa699;
    background-color: rgba(10, 166, 153, 0.3);
    pointer-events: none; /* 确保不会阻止用户拖拽事件 */
}

.el-header {
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    border-bottom: solid 1px var(--el-border-color-light);
    background-color: var(--el-bg-color);
}
</style>
